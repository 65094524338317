// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Discount Badge Styling */
.product-card .discount-badge {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #dc3545;
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 3px;
  z-index: 2;
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchInput/Search.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":["/* Discount Badge Styling */\r\n.product-card .discount-badge {\r\n  position: absolute;\r\n  top: 0px;\r\n  left: 0px;\r\n  background-color: #dc3545;\r\n  color: white;\r\n  font-size: 12px;\r\n  font-weight: 500;\r\n  padding: 5px 10px;\r\n  border-radius: 3px;\r\n  z-index: 2;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
